import * as React from 'react';
import {useEffect, useState} from 'react';
import {Grid, InputAdornment, LabelDisplayedRowsArgs, TablePagination} from "@mui/material";
import {TextField} from "../App/components/Inputs/Textfield";
import {FormikValues, useFormik} from "formik";
import {Filters as FiltersIcon} from "../App/components/Svg/Filters";
import {Select} from "../App/components/Inputs/Select";
import {useAppDispatch, useAppSelector} from "../App/hooks";
import {ICustomer} from "../App/interface/customer";
import {DateRangePicker} from 'rsuite';
import {SelectTree} from "../App/components/Inputs/SelectTree";
import {Autocomplete} from "../App/components/Inputs/Autocomplete";
import {useDebounce} from "use-debounce";
import * as AppStorage from '../App/helpers/storage';
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import {endings} from "../App/helpers/endings";
import {MagnifyingGlass} from "../App/components/Svg/MagnifyingGlass";
import {IStatus} from "../App/interface/status";
import {IConductionMethod} from "../App/interface/conductionMethod";
import {ICategory} from "../App/interface/category";
import {IItem, ILot, IPurchase, IPurchasesQuery, IPurchasesResponse} from "./interface/purchase";
import {
    ApplyFiltersButton,
    ClearFiltersButton,
    Content,
    Dust,
    ExpandLessIcon,
    ExpandLessIconActive,
    Filters,
    FiltersLabel,
    FiltersLabelActive,
    Footer,
    FooterButton,
    FooterWrap,
    OpenFilterButton,
    Page,
    Purchase,
    PurchaseClient,
    PurchaseHeader,
    PurchaseHeaderBottom,
    PurchaseHeaderBottomItem,
    PurchaseHeaderBottomItemDiv,
    PurchaseHeaderBottomItemSpan,
    PurchaseHeaderTop,
    PurchaseInfo,
    PurchaseName,
    PurchaseStatus,
    PurchaseTable,
    PurchaseTableBody,
    PurchaseTableBodyAction,
    PurchaseTableHead,
    TableAction,
    TitleFieldDate,
    TitleFieldNmc
} from "./styles/guest";
import {fetchPurchaseItems, fetchPurchases} from "./actions/guest";
import {
    fetchCategories,
    fetchCategoriesTree,
    fetchConductionMethods,
    fetchCustomers,
    fetchStatuses
} from "../App/actions/app";
import {IFormik} from "../App/interface/formik";
import {getPurchaseStatusCircle} from "../App/components/Status";
import {parseCurrency} from "../App/helpers/currency";

const FILTERS_KEY = 'MAIN_PAGE_FILTERS';
const FORMIK_INITIAL_VALUES = {
    search: '',
    statuses: [],
    conduction_methods: [],
    categories: [],
    customers: [],
    price_from: '',
    price_to: '',
    deadlineStart: [null, null],
    deadlineEnd: [null, null],
};
export const Guest = () => {
    const dispatch = useAppDispatch();
    const purchases: IPurchasesResponse = useAppSelector((state) => state.guest.purchases)
    const statuses: IStatus[] = useAppSelector((state) => state.app.statuses)
    const categoriesTree: ICategory[] = useAppSelector((state) => state.app.categoriesTree)
    const categories: ICategory[] = useAppSelector((state) => state.app.categories)
    const customers: ICustomer[] = useAppSelector((state) => state.app.customers)
    const conductionMethods: IConductionMethod[] = useAppSelector((state) => state.app.conductionMethods)
    const [isOpenAllFilters, setIsOpenAllFilters] = useState<boolean>(false);
    const [customersSearch, setCustomersSearch] = useState<string>('');
    const [rowsPerPage, setRowsPerPage] = useState<number>(15)
    const [page, setPage] = useState<number>(0)
    const [search, setSearch] = useState<string>('');
    const [customersSearchDebounce] = useDebounce(customersSearch, 900);
    const [searchDebounce] = useDebounce(search, 900);
    const [filters, setFilters] = useState<IPurchasesQuery>({});
    const [isLoadFilters, setIsLoadFilters] = useState<boolean>(false);
    const [openLots, setOpenLots] = useState<number[]>([])

    useEffect((): void => {
        getData().then((): void => {
        });
    }, []);

    useEffect((): void => {
        if (
            !isLoadFilters &&
            statuses.length &&
            conductionMethods.length &&
            categories.length &&
            customers.length
        ) {
            const jsonFilters = AppStorage.get(FILTERS_KEY);
            if (!isLoadFilters && jsonFilters) {
                setIsLoadFilters(true);
                const decodeFilters: IPurchasesQuery = JSON.parse(jsonFilters);
                const saveFilters: IPurchasesQuery = {
                    ...decodeFilters,
                    ...{
                        limit: decodeFilters?.limit ?? rowsPerPage,
                        page: decodeFilters?.page ?? page + 1,
                    }
                };
                setFilters(saveFilters);
                const filtersStatuses: number[] = getIdsFromString(decodeFilters.statuses);
                const filtersCategories: number[] = getIdsFromString(decodeFilters.categories);
                const filtersCustomers: number[] = getIdsFromString(decodeFilters.customers);
                const filtersConductionMethods: string[] = geKeysFromString(decodeFilters.conduction_methods);
                const formikValues = {
                    search: decodeFilters.search,
                    statuses: filtersStatuses.length ? statuses.filter((status: IStatus) => filtersStatuses.includes(status.index)) : [],
                    categories: filtersCategories.length ? categories.filter((category: ICategory) => filtersCategories.includes(category.id)) : [],
                    customers: filtersCustomers.length ? customers.filter((customer: ICustomer) => filtersCustomers.includes(customer.id)) : [],
                    conduction_methods: filtersConductionMethods.length ? conductionMethods.filter((conductionMethod: IConductionMethod) => filtersConductionMethods.includes(conductionMethod.key)) : [],
                    price_from: decodeFilters.price_from ?? null,
                    price_to: decodeFilters.price_to ?? null,
                    deadlineStart: [getDate(decodeFilters.application_start_from), getDate(decodeFilters.application_start_to)],
                    deadlineEnd: [getDate(decodeFilters.application_deadline_from), getDate(decodeFilters.application_deadline_to)],
                };
                if (formikValues.statuses.length ||
                    formikValues.categories.length ||
                    formikValues.customers.length ||
                    formikValues.conduction_methods.length ||
                    formikValues.price_from ||
                    formikValues.price_to ||
                    formikValues.deadlineStart[0] ||
                    formikValues.deadlineStart[1] ||
                    formikValues.deadlineEnd[0] ||
                    formikValues.deadlineEnd[1]
                ) {
                    setIsOpenAllFilters(true);
                }
                formik.setValues(formikValues);
                dispatch(fetchPurchases(saveFilters))
            } else {
                setIsLoadFilters(true);
                const saveFilters = {
                    limit: rowsPerPage,
                    page: page + 1,
                };
                setFilters(saveFilters);
                dispatch(fetchPurchases(saveFilters))
            }
        }
    }, [statuses, conductionMethods, categories, categoriesTree, customers]);

    useEffect((): void => {
        if (isLoadFilters) {
            const newFilters: IPurchasesQuery = {
                ...filters,
                ...{
                    search: searchDebounce
                }
            };
            saveFiltersAndLoadPurchases(newFilters);
        }
    }, [searchDebounce]);


    useEffect((): void => {
        if (isLoadFilters) {
            dispatch(fetchCustomers({search: customersSearchDebounce}))
        }
    }, [customersSearchDebounce]);

    const saveFiltersAndLoadPurchases = (newFilters: IPurchasesQuery): void => {
        setFilters(newFilters);
        AppStorage.set(FILTERS_KEY, JSON.stringify(newFilters));
        dispatch(fetchPurchases(newFilters));
    }

    const getDate = (value: string | undefined): Date | null => value ? new Date(Date.parse(value)) : null;

    const getData = async (): Promise<any> => {
        dispatch(fetchCustomers({}));
        dispatch(fetchStatuses());
        dispatch(fetchConductionMethods());
        dispatch(fetchCategories());
        dispatch(fetchCategoriesTree());
    }

    const getIdsFromString = (str: string | undefined): number[] => str ? str.split(',').map((item: string) => parseInt(item)) : [];
    const geKeysFromString = (str: string | undefined): string[] => str ? str.split(',').map((item: string) => item) : [];


    const formik: IFormik = useFormik({
        initialValues: FORMIK_INITIAL_VALUES,
        onSubmit: (values: FormikValues): void => {
            const newFilters: IPurchasesQuery = {
                limit: rowsPerPage,
                page: page + 1,
                search: values.search,
                statuses: values.statuses.map((item: IStatus) => item.index).join(','),
                conduction_methods: values.conduction_methods.map((item: IConductionMethod) => item.key).join(','),
                customers: values.customers.map((item: ICustomer) => item.id).join(','),
                categories: values.categories.map((item: ICategory) => item.id).join(','),
                price_from: isNaN(parseFloat(values.price_from.replaceAll(',', '')))
                    ? null
                    : parseFloat(values.price_from.replaceAll(',', '')),
                price_to: isNaN(parseFloat(values.price_to.replaceAll(',', '')))
                    ? null
                    : parseFloat(values.price_to.replaceAll(',', '')),
                application_start_from: values.deadlineStart?.[0] && changeDateFormat(values.deadlineStart[0]),
                application_start_to: values.deadlineStart?.[1] && changeDateFormat(values.deadlineStart[1]),
                application_deadline_from: values.deadlineEnd?.[0] && changeDateFormat(values.deadlineEnd[0]),
                application_deadline_to: values.deadlineEnd?.[1] && changeDateFormat(values.deadlineEnd[1])
            };
            saveFiltersAndLoadPurchases(newFilters);
        }
    });

    const changeDateFormat = (date: Date): string => {
        const newDate: Date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        const year: number = newDate.getFullYear();
        const month: number = newDate.getMonth() + 1;
        const day: number = newDate.getDate();

        return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    };

    const getOpenFiltersButton = (): JSX.Element => {
        return <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={3}>
                {isOpenAllFilters ? <OpenFilterButton
                    variant="text"
                    onClick={openFilters}
                >
                    <FiltersIcon color='#FA4022'/>
                    <FiltersLabelActive>Фильтры</FiltersLabelActive>
                    <ExpandLessIconActive
                        style={{rotate: isOpenAllFilters ? '0deg' : '180deg'}}/>
                </OpenFilterButton> : <OpenFilterButton
                    variant="text"
                    onClick={openFilters}
                >
                    <FiltersIcon color='black'/>
                    <FiltersLabel>Фильтры</FiltersLabel>
                    <ExpandLessIcon/>
                </OpenFilterButton>}
            </Grid>
            <Grid item xs={1.5}>
                <ClearFiltersButton
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={clearFilters}
                >
                    Очистить все
                </ClearFiltersButton>
            </Grid>
        </Grid>
    }

    const openFilters = () => setIsOpenAllFilters(!isOpenAllFilters)


    const handleChangePage = (event: any, newPage: number): void => {
        setPage(newPage)
        const newFilters: IPurchasesQuery = {
            ...filters,
            ...{
                page: newPage + 1
            }
        };
        saveFiltersAndLoadPurchases(newFilters);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(+event.target.value)
        setPage(0)
        const newFilters: IPurchasesQuery = {
            ...filters,
            ...{
                page: 1,
                limit: +event.target.value
            }
        };
        saveFiltersAndLoadPurchases(newFilters);
    }

    const clearFilters = (): void => {
        const newFilters: IPurchasesQuery = {
            limit: 15,
            page: 1,
        }
        setPage(0);
        setRowsPerPage(15);
        setFilters(newFilters);
        formik.setValues(FORMIK_INITIAL_VALUES);
        AppStorage.set(FILTERS_KEY, JSON.stringify(newFilters));
        dispatch(fetchPurchases(newFilters));
    }

    const getItemsByLot = (event: React.MouseEvent<HTMLElement>, orderId: number, lotId: number, count: number): void => {
        event.stopPropagation();
        if (count > 5) {
            setOpenLots(openLots.concat([lotId]));
        } else {
            dispatch(fetchPurchaseItems({orderId, lotId})).then(
                (): void => {
                    setOpenLots(openLots.concat([lotId]));
                }
            );
        }

    }
    const closeLot = (event: React.MouseEvent<HTMLElement>, id: number): void => {
        event.stopPropagation();
        setOpenLots(openLots.filter((item: number): boolean => item !== id));
    }


    return <Page container justifyContent="center" alignItems="center" direction="row">
        <Content item>
            <Filters>
                <TextField
                    name="search"
                    placeholder={`Поиск по номеру, наименованию, заказчику`}
                    formik={formik}
                    onChange={(value: string): void => {
                        formik.setFieldValue('search', value);
                        setSearch(value)
                    }}
                    inputProps={{
                        startAdornment: (
                            <InputAdornment position="start" style={{marginLeft: '15px'}}>
                                <MagnifyingGlass/>
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                />
                {getOpenFiltersButton()}
                {isOpenAllFilters ? <React.Fragment>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={3}>
                            <SelectTree
                                formik={formik}
                                label={'Категории'}
                                name={'categories'}
                                options={categoriesTree}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                formik={formik}
                                label={'Статус'}
                                name={'statuses'}
                                options={statuses}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                formik={formik}
                                label={'Способ проведения'}
                                name={'conduction_methods'}
                                options={conductionMethods}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                formik={formik}
                                label={'Заказчик'}
                                name={'customers'}
                                options={customers}
                                search={customersSearch}
                                onSearch={setCustomersSearch}
                                renderValue={(option: ICustomer) => option.legal_detail.name}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={3} style={{display: 'flex'}}>
                            <TitleFieldNmc>НМЦ</TitleFieldNmc>
                            <TextField
                                type={'number'}
                                name="price_from"
                                placeholder={`От 24 0000 ₽`}
                                formik={formik}
                                inputProps={{
                                    disableUnderline: true
                                }}
                            />
                            <Dust/>
                        </Grid>
                        <Grid item xs={3} style={{display: 'flex'}}>
                            <TextField
                                type={'number'}
                                name="price_to"
                                placeholder={`до 2 400 000 ₽`}
                                formik={formik}
                                inputProps={{
                                    disableUnderline: true
                                }}
                            />
                            <TitleFieldDate>
                                Даты
                            </TitleFieldDate>
                        </Grid>
                        <Grid item xs={3}>
                            <DateRangePicker
                                value={formik.values.deadlineStart}
                                onChange={(e: any): void => {
                                    formik.setFieldValue('deadlineStart', e);
                                }}
                                placeholder="Даты размещения"
                                size="lg"
                                character=" – "
                                format="dd.MM.yyyy"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateRangePicker
                                value={formik.values.deadlineEnd}
                                placeholder="Даты окончания"
                                onChange={(e: any): void => {
                                    formik.setFieldValue('deadlineEnd', e);
                                }}
                                size="lg"
                                character=" – "
                                format="dd.MM.yyyy"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={3}>
                            <ApplyFiltersButton
                                style={{width: '100%'}}
                                disableElevation
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={() => formik.handleSubmit()}
                            >
                                Применить фильтры
                            </ApplyFiltersButton>
                        </Grid>
                    </Grid>
                </React.Fragment> : null}
            </Filters>
            {purchases?.data.length ? purchases.data.map((purchase: IPurchase, index: number) => {
                return <Purchase key={index}>
                    <PurchaseHeader>
                        <PurchaseHeaderTop>
                            <PurchaseName>{purchase.id ? `№${purchase.id} - ` : null} {purchase.name ? purchase.name : null}</PurchaseName>
                            <PurchaseInfo>
                                <PurchaseClient>
                                    <div>{purchase.company.legal_detail.name}</div>
                                    {getPurchaseStatusCircle(-1)}
                                    <div>{purchase.regions.name}</div>
                                </PurchaseClient>
                                <PurchaseStatus>
                                    <div>Статус:</div>
                                    {getPurchaseStatusCircle(purchase.status.index)}
                                    {purchase.status.name}
                                </PurchaseStatus>
                            </PurchaseInfo>
                        </PurchaseHeaderTop>
                        <PurchaseHeaderBottom>
                            <PurchaseHeaderBottomItem>
                                <PurchaseHeaderBottomItemDiv>Кол-во лотов</PurchaseHeaderBottomItemDiv>
                                <PurchaseHeaderBottomItem>{endings(purchase.lots.length, ['лот', 'лота', 'лотов'])}</PurchaseHeaderBottomItem>
                            </PurchaseHeaderBottomItem>
                            <PurchaseHeaderBottomItem>
                                <PurchaseHeaderBottomItemDiv>Общая стоимость</PurchaseHeaderBottomItemDiv>
                                <PurchaseHeaderBottomItemDiv>{parseCurrency(purchase.is_unit_price ? purchase.lots.reduce((total: number, lot: ILot) => total + (lot.max_contract_price ?? 0), 0) : purchase.average_price, purchase.currency.code)}</PurchaseHeaderBottomItemDiv>
                            </PurchaseHeaderBottomItem>
                            <PurchaseHeaderBottomItem>
                                <PurchaseHeaderBottomItemDiv>Метод определения поставщика</PurchaseHeaderBottomItemDiv>
                                <PurchaseHeaderBottomItemDiv>{purchase.conduction_method ? purchase.conduction_method.name : (purchase.type === 'static' ? 'Статический запрос' : 'Динамический запрос')}</PurchaseHeaderBottomItemDiv>
                            </PurchaseHeaderBottomItem>
                            <PurchaseHeaderBottomItem>
                                <PurchaseHeaderBottomItemDiv>Время размещения</PurchaseHeaderBottomItemDiv>
                                <PurchaseHeaderBottomItemDiv>от {format(new Date(purchase.application_start), 'H:mm PP', {locale: ru})}</PurchaseHeaderBottomItemDiv>
                                <PurchaseHeaderBottomItemSpan>(МСК {format(new Date(new Date(purchase.application_start).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})</PurchaseHeaderBottomItemSpan>
                            </PurchaseHeaderBottomItem>
                            <PurchaseHeaderBottomItem>
                                <PurchaseHeaderBottomItemDiv>Подача предложений</PurchaseHeaderBottomItemDiv>
                                <PurchaseHeaderBottomItemDiv>до {format(new Date(purchase.application_deadline), 'H:mm PP', {locale: ru})}</PurchaseHeaderBottomItemDiv>
                                <PurchaseHeaderBottomItemSpan>(МСК {format(new Date(new Date(purchase.application_deadline).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})</PurchaseHeaderBottomItemSpan>
                            </PurchaseHeaderBottomItem>
                        </PurchaseHeaderBottom>
                    </PurchaseHeader>
                    <div className="purchase__body">
                        {purchase.lots && purchase.lots.map((lot: ILot, index: number) => {
                            const isOpenLot: boolean = openLots.includes(lot.id);
                            return <PurchaseTable
                                key={index}
                                onClick={(): void => {
                                    window.location.href = `/lots/${lot.id}`;
                                }}
                            >
                                <thead>
                                <tr>
                                    <PurchaseTableHead style={{width: '80px'}}>№ Лота</PurchaseTableHead>
                                    <PurchaseTableHead style={{width: '450px'}}>Объект закупки</PurchaseTableHead>
                                    <PurchaseTableHead style={{width: '80px'}}>Кол-во</PurchaseTableHead>
                                    <PurchaseTableHead style={{width: '165px'}}>НМЦ</PurchaseTableHead>
                                    <PurchaseTableHead style={{width: '95px'}}>Отсрочка</PurchaseTableHead>
                                    <PurchaseTableHead style={{width: '170px'}}>Срок исполнения</PurchaseTableHead>
                                </tr>
                                </thead>
                                <tbody>
                                {lot.items.map((item: IItem, itemIndex: number) => {
                                    if (itemIndex <= 4 || isOpenLot) {
                                        return <React.Fragment>
                                            <tr key={`${index}_${itemIndex}`}>
                                                <PurchaseTableBody>{itemIndex === 0 ? (lot.number ? lot.number : lot.index) : ''}</PurchaseTableBody>
                                                <PurchaseTableBody><p>{item.name}</p></PurchaseTableBody>
                                                <PurchaseTableBody>{item.amount}</PurchaseTableBody>
                                                <PurchaseTableBody>{parseCurrency(item.average_price, purchase.currency.code)}</PurchaseTableBody>
                                                <PurchaseTableBody>{lot.postponement}</PurchaseTableBody>
                                                <PurchaseTableBody>{lot.contract_time}</PurchaseTableBody>
                                            </tr>
                                            {(!isOpenLot && itemIndex === 4 && ((lot.count.items - lot.items.length) > 0 || lot.items.length > 5)) ?
                                                <tr key={`${index}_${itemIndex}`}>
                                                    <PurchaseTableBodyAction></PurchaseTableBodyAction>
                                                    <PurchaseTableBodyAction
                                                        colSpan={5}
                                                        onClick={(event: React.MouseEvent<HTMLElement>) => getItemsByLot(event, purchase.id, lot.id, lot.items.length)}
                                                    >
                                                        <TableAction> Показать еще {lot.count.items - 5}</TableAction>
                                                    </PurchaseTableBodyAction>
                                                </tr> : null}
                                        </React.Fragment>
                                    }
                                })}
                                {
                                    isOpenLot ? <tr>
                                        <PurchaseTableBodyAction></PurchaseTableBodyAction>
                                        <PurchaseTableBodyAction
                                            colSpan={5}
                                            onClick={(event: React.MouseEvent<HTMLElement>) => closeLot(event, lot.id)}>
                                            <TableAction>Скрыть</TableAction>
                                        </PurchaseTableBodyAction>
                                    </tr> : null}
                                </tbody>
                            </PurchaseTable>
                        })}
                    </div>
                </Purchase>
            }) : null}
            <Footer>
                <FooterWrap>
                    <TablePagination
                        rowsPerPageOptions={[5, 15, 25, 50, 100]}
                        labelRowsPerPage={'Записей на странице:'}
                        labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs):string => `${paginationInfo.from} из ${paginationInfo.count}`}
                        count={purchases.meta.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={handleChangePage}
                        sx={{
                            '& .Mui-focusVisible': {
                                color: 'red'
                            }
                        }}
                    />
                </FooterWrap>
                <FooterButton
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={(): void => {
                        window.location.href = '/login';
                    }}
                >
                    Войти
                </FooterButton>
            </Footer>
        </Content>
    </Page>
}
